






























import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'
import { QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { PaginationProps } from '../../../../dsl/molecules/Pagination'

import { StructureConfigurable } from '../../../../support/mixins'
import { ConnectorErrors, Inject, logger } from '../../../../support'
import { ISiteService, SitePropertyTypes, SiteServiceType } from '../../../../contexts'

import { AuthMixin, IAuthMixin } from '../../../auth/shared'

import { Loader } from '../../../shared/molecules/Loader'

import {
  OrdersListItem,
  OrdersListItemPayment,
  OrdersListItemProps,
  translateOrderDataToOrderListItem
} from '../../molecules/OrdersListItem'
import { IOrdersRepository, OrdersRepositoryType } from '../../contracts/repository'

import { CustomEmptyOrderList } from './partials/EmptyOrderList'
import {
  OrderListConfig,
  ORDERS_LIST_ITEM_COMPONENT_CONFIG_MAP,
  ORDERS_LIST_ITEM_COMPONENT_KEY,
  OrdersListItemConfig,
  OrdersListItemLayout
} from './OrderList.config'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.halimjonov@movecloser.pl> (edited)
 */
@Component<OrdersList>({
  name: 'OrderList',
  components: { CustomEmptyOrderList, Loader, OrdersListItem, OrdersListItemPayment },
  created (): void {
    if (!this.isWaitingForAuth) {
      this.loadOrders()
    }

    this.config = this.getComponentConfig(
      ORDERS_LIST_ITEM_COMPONENT_KEY,
      { ...ORDERS_LIST_ITEM_COMPONENT_CONFIG_MAP }
    )
  }
})
export class OrdersList extends Mixins<IAuthMixin, StructureConfigurable>(
  AuthMixin,
  StructureConfigurable
) {
  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  protected config!: OrdersListItemConfig

  public get layout () {
    return OrdersListItemLayout
  }

  public currentPage: number = this.$route.query?.page
    ? parseInt(this.$route.query.page.toString(), 10) : 1

  public ORDERS_PER_PAGE: number = OrderListConfig.ordersPerPage

  public totalOrders: number = 0
  /**
   * Error message
   */
  public errorMessage: string | null = null
  /**
   * Determines the loading state of the component.
   */
  public isLoading: boolean = true
  /**
   * State of orders
   */
  public isSubmitted: boolean | null = null

  /**
   * Orders
   */
  public orders: OrdersListItemProps[] | null = null

  public get emptyListLink (): SitePropertyTypes | undefined {
    const emptyServiceLink = this.siteService.getActiveSite().properties.productsList
    return this.useServiceDefinedLinkForEmpty && emptyServiceLink ? emptyServiceLink : '/'
  }

  public get ordersListItemLayout (): string {
    return this.getConfigProperty('layout')
  }

  public get daysForPaymentRetry (): number {
    return this.getConfigProperty('daysForPaymentRetry')
  }

  public get orderAgainButton (): boolean {
    return this.getConfigProperty('orderAgainButton')
  }

  public get useServiceDefinedLinkForEmpty (): boolean {
    return this.getConfigProperty('useServiceDefinedLinkForEmpty')
  }

  public get basePaginationProps (): Omit<PaginationProps, 'currentPage'> {
    return {
      showDirectionButtons: true,
      totalPages: Math.ceil(this.totalOrders / this.ordersPerPage),
      totalVisible: OrderListConfig.totalVisiblePaginationElements,
      link: { target: { path: this.$route.path, query: this.filterQuery }, param: 'page' }
    }
  }

  public get filterQuery (): QueryParams {
    return {
      perPage: this.ordersPerPage,
      ...this.$route.query
    }
  }

  /**
   * Determines whether list is empty.
   */
  public get isEmptyOrderList (): boolean {
    if (this.isLoading) {
      return false
    }

    if (!this.orders) {
      return true
    }

    return !(this.orders.length > 0)
  }

  public get ordersPerPage (): number {
    if (!this.$route.query.perPage) {
      return this.ORDERS_PER_PAGE
    }

    return Number(this.$route.query.perPage)
  }

  public get showPagination (): boolean {
    return !this.isLoading && !this.isEmptyOrderList && (this.totalOrders > this.ordersPerPage)
  }

  /**
   * Loads the orders and translates the order to OrdersListItem.
   */
  public async loadOrders () {
    this.isLoading = true
    this.errorMessage = null
    this.isSubmitted = null

    try {
      const ordersResponse = await this.ordersRepository.loadOrders(
        this.currentPage,
        this.ordersPerPage
      )
      const orders = [...ordersResponse.orders]
      this.totalOrders = ordersResponse.totalCount
      if (!orders) {
        return
      }

      this.isSubmitted = true

      this.orders = orders.map((order) => translateOrderDataToOrderListItem(order))
    } catch (error) {
      this.isSubmitted = false
      this.setError(error as Error)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Determines the type of error and logs its message.
   */
  private setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.errorMessage = error.message
  }

  @Watch('isWaitingForAuth')
  protected async onAuthState (value: boolean, oldValue: boolean): Promise<void> {
    if (value || value === oldValue) {
      return
    }

    await this.loadOrders()
  }
}

export default OrdersList
