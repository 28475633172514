














import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject as VueInject } from 'vue-property-decorator/lib/decorators/Inject'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Basic'
})
export class Basic extends Vue {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  public readonly allOrdersRoute!: object

  @Prop({ type: String, required: true })
  public readonly returnOrderPath!: string

  @Prop({ type: String, required: true })
  public readonly returnOrderBtnVariant!: string

  @Prop({ type: Boolean, required: true })
  public readonly showReturnButton!: boolean
}
export default Basic
