














/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
import { Component, Prop, Vue } from 'vue-property-decorator'

import { SitePropertyTypes } from '../../../../../../contexts'

@Component({
  name: 'EmptyOrderList'
})
export class EmptyOrderList extends Vue {
  @Prop({ type: String, required: false, default: '/' })
  public readonly link?: SitePropertyTypes | undefined
}

export default EmptyOrderList
