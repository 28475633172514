


































































































import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { AnyObject, ResourceActionFailed } from '@movecloser/front-core'

import {
  ConnectorErrors,
  defaultProvider,
  Inject,
  IS_MOBILE_PROVIDER_KEY,
  logger
} from '../../../support'
import {
  MappedOrder,
  PossibleOrderStatus,
  ProductOrderData,
  shippingMethodsIconsRegistry,
  SingleOrderData
} from '../../../contexts/orders/contracts/orders'
import {
  ISiteService,
  SiteServiceType
} from '../../../contexts'
import { StructureConfigurable } from '../../../support/mixins'

import { CartItemProps } from '../../checkout/molecules/CartItem/CartItem.contracts'
import { CheckoutServiceType, ICheckoutService } from '../../checkout/services/checkout'
import { IProductsRepository, ProductsRepositoryType } from '../../products/contracts/repositories'
import { Loader } from '../../shared/molecules/Loader'
import { OrdersList } from '../../orders/organisms/OrdersList'
import { ProfileWrapper } from '../../profile/shared'
import { SingleCartItem } from '../../checkout/organisms/SingleCartItem'

import { IOrdersRepository, OrdersRepositoryType } from '../contracts/repository'
import { OrdersListItemPayment } from '../molecules/OrdersListItem'
import { translateMissingOrderItems, translateToOrderItem } from '../support/order.helpers'
import { RouteNames as OrderRouteNames } from '../routes'
import {
  ORDERS_LIST_ITEM_COMPONENT_CONFIG_MAP,
  ORDERS_LIST_ITEM_COMPONENT_KEY, OrdersListItemConfig, OrdersListItemLayout
} from '../organisms/OrdersList/OrderList.config'
import { OrderStatusesMixin } from '../../shared/mixins/orderStatuses.mixin'
import OrderStatus from '../molecules/OrderStatus/OrderStatus.vue'

import { Default } from './summary/variants/Default.vue'
import { Vertical } from './summary/variants/Vertical.vue'

import { Basic } from './actions/variants/Basic.vue'
import { Extended } from './actions/variants/Extended.vue'

import { Single } from './address/variants/Single.vue'
import { Separated } from './address/variants/Separated.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<Order>({
  name: 'Order',
  components: { Basic, Default, Extended, Loader, OrdersList, OrdersListItemPayment, ProfileWrapper, SingleCartItem, OrderStatus, Vertical, Single, Separated },
  created () {
    this.config = this.getComponentConfig(ORDERS_LIST_ITEM_COMPONENT_KEY, { ...ORDERS_LIST_ITEM_COMPONENT_CONFIG_MAP })
    /**
     * @inheritDoc
     */
    this.loadOrder(this.$route.params.id)
  }
})
export class Order extends Mixins(StructureConfigurable, OrderStatusesMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  protected config!: OrdersListItemConfig

  /**
   * Error message
   */
  public errorMessage: string | null = null
  /**
   * Determines the loading state of the component.
   */
  public isLoading: boolean = true
  /**
   * Single order collection
   */
  public order: SingleOrderData[] | null = null
  /**
   * Collection of orders with mapped data
   */
  public mapped!: MappedOrder | null

  /**
   * Collection of products ordered by customer but not resolved (missing, withdrawn, etc...)
   */
  public notFoundItems: CartItemProps[] = []

  public get layout () {
    return OrdersListItemLayout
  }

  public get cartItemVariant (): string {
    return this.getConfigProperty<string>('cartItemVariant') ?? 'default'
  }

  public get hasIterator (): string {
    return this.getConfigProperty('hasIterator')
  }

  public get ordersListItemLayout (): string {
    return this.getConfigProperty('layout')
  }

  public get returnOrderBtnVariant (): string {
    return this.getConfigProperty('returnOrderBtnVariant')
  }

  public get summaryVariant (): string {
    return this.getConfigProperty('summaryVariant')
  }

  public get actionVariant (): string {
    return this.getConfigProperty('actionVariant')
  }

  public get allOrdersRoute (): { name: string } {
    return {
      name: `orders.${OrderRouteNames.History}`
    }
  }

  public get daysForPaymentRetry (): number {
    return this.getConfigProperty('daysForPaymentRetry')
  }

  public get showPaymentAmount (): number {
    return this.getConfigProperty('showPaymentAmount')
  }

  public get addressVariant (): number {
    return this.getConfigProperty('addressVariant')
  }

  public get decorator (): number {
    return this.getConfigProperty('headerDecorator')
  }

  public get mappedOrder (): MappedOrder | null {
    return this.mapped
  }

  public get returnOrderPath () {
    return this.siteService.getActiveSiteUrls().returnOrder
  }

  public get invoice () {
    if (!this.mapped?.invoice) {
      return ''
    }

    return this.mapped.invoice
  }

  /**
   * Get status that should enable retry payment action
   */
  public get statusesToReturn (): Array<string> {
    return Object.keys(this.returnOrderStatuses)
  }

  public get shippingIcon (): string | null | undefined {
    if (!this.mapped) {
      return null
    }

    const iconsRegistry = Object.values(shippingMethodsIconsRegistry)
    return iconsRegistry.find((iconName) => {
      return this.mapped?.shippingMethod.toLocaleLowerCase().startsWith(iconName.toLowerCase())
    })
  }

  /**
   * Prepare status translation.
   * @param status
   */
  public status (status: string): string {
    const isStatusDefined = Object.values(PossibleOrderStatus).includes(status)

    if (!isStatusDefined) {
      return '-'
    }

    return this.$t(`front.orders.OrdersListItem.statuses.${status}`).toString()
  }

  /**
   * Load single order data
   * @param order
   * @protected
   */
  protected async loadOrder (order: string) {
    this.isLoading = true

    try {
      const response: SingleOrderData | null = await this.ordersRepository.loadSingleOrder(order)

      if (!response) {
        return
      }

      /** Order SKUs collection */
      const skus: string[] = []
      for (const [k, v] of Object.entries(response)) {
        if (k === 'products') {
          for (const product of Object.values(v as ProductOrderData)) {
            skus.push(product.sku)
          }
        }
      }

      if (skus.length === 0) {
        return
      }

      try {
        /** Load order products data */
        const productsResponse = await this.productsRepository.loadProductsBySkus(skus)

        if (!productsResponse) {
          return
        }

        /** Compose finally shaped order data */
        this.mapped = {
          ...response,
          products: translateToOrderItem(response.products, productsResponse)
        }

        /** Find products that couldn't be resolved (are missing, withdrawn, etc...) */
        const foundSkus: (string | undefined)[] = []
        for (const product of this.mapped.products) {
          foundSkus.push(product.sku)
        }

        this.notFoundItems = translateMissingOrderItems(
          response.products.filter((item) => !foundSkus.includes(item.sku)),
          this.$i18n
        )

        try {
          /** Retrieve order payment method data */
          const paymentMethods = await this.checkoutService.loadPaymentMethods(this.mapped.costs.total)

          if (!paymentMethods) {
            return
          }

          this.mapped = {
            ...this.mapped,
            paymentMethod: paymentMethods.filter((method: AnyObject) => method.id === this.mapped?.paymentMethod)[0]
          }
        } catch (e) {
        }
      } catch (e) {
        this.setError(e as Error)
      }
    } catch (e) {
      this.setError(e as Error)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Determines the type of error and logs its message.
   */
  private setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.errorMessage = error.message
  }
}

export default Order
