




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MappedOrder } from '../../../../../contexts/orders/contracts/orders'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Default'
})
export class Default extends Vue {
  @Prop({ type: Object, required: true })
  public readonly mappedOrder!: MappedOrder
}
export default Default
