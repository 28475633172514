






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MappedOrder } from '../../../../../contexts/orders/contracts/orders'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Vertical'
})
export class Vertical extends Vue {
  @Prop({ type: Object, required: true })
  public readonly mappedOrder!: MappedOrder

  public get receiptIcon (): string {
    return require('../partials/receipt.svg')
  }

  public get sumProducts () {
    return this.mappedOrder.products.reduce((acc, item) => {
      if (!item.quantity) {
        return acc
      }
      return acc + item.quantity
    }, 0)
  }
}
export default Vertical
