



















import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Inject as VueInject } from 'vue-property-decorator/lib/decorators/Inject'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { BaseCartMixin } from '../../../../checkout/shared/mixins/base-cart.mixin'

import { IOrderRenewalService, OrderRenewalServiceKey } from '../../../services/order-renewal'
import { ToastMixin } from '../../../../shared'
import { MappedOrder } from '../../../../../contexts/orders/contracts/orders'
import { ISiteService, SiteServiceType } from '../../../../../contexts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Extended'
})
export class Extended extends Mixins(BaseCartMixin, ToastMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(OrderRenewalServiceKey)
  protected readonly orderRenewalService?: IOrderRenewalService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: String, required: false })
  public readonly invoice!: string

  @Prop({ type: Object, required: true })
  public readonly mappedOrder!: MappedOrder

  public isRenewalPending: boolean = false

  public get renewOrder () {
    const renewOrder = Object.create(this.mappedOrder)
    return {
      createdAt: renewOrder.createdAt,
      id: renewOrder.id,
      products: renewOrder.products,
      productsCost: renewOrder.costs.products,
      shippingCost: renewOrder.costs.shipping,
      status: renewOrder.status,
      totalCost: renewOrder.costs.total,
      updatedAt: renewOrder.updatedAt ?? renewOrder.createdAt
    }
  }

  public async orderAgain () {
    if (!this.orderRenewalService || !this.renewOrder) {
      return false
    }
    try {
      const renewedCart = await this.orderRenewalService.renewCustomerOrder(this.renewOrder)
      if (!renewedCart) {
        return
      }
      this.refreshCart(renewedCart)
      this.$emit('reload')
      this.showToast(this.$t('front.orders.views.OrderView.orderAgainSuccess').toString(), 'success')
    } catch (e) {
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.isRenewalPending = false
    }
  }

  public get ecommerceLink () {
    if (!this.siteService || !this.siteService.getProperty('ecommercePageUrl')) {
      return ''
    }

    return this.siteService.getProperty('ecommercePageUrl')
  }
}
export default Extended
