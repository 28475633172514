























import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'Single'
})
export class Single extends Vue {
  @Prop({ type: Object, required: true })
  public readonly mapped!: object

  @Prop({ type: Object, required: true })
  public readonly mappedOrder!: object
}
export default Single
